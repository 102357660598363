import React, {useState} from 'react';
import MyInput from "../UI/input/MyInput";
import MyButton from "../UI/myButton/MyButton";
import {useDispatch} from "react-redux";

const AddItem = ({sp}) => {

    const dispatch = useDispatch();
    const [post, setPost] = useState({title: '', body: ''});

    const addNewPost = (e) => {
        e.preventDefault();
        const newPost = {
            ...post, id: Date.now()
        };
        sp(false);
        dispatch({type: "ADD", payload: newPost});
        setPost({title: '', body: ''});

    };


    return (
        <form>
            <MyInput
                type="text"
                placeholder="Название"
                value={post.title}
                onChange={e => setPost({...post, title: e.target.value})}
            />
            <MyInput
                type="text"
                placeholder="Описание"
                value={post.body}
                onChange={e => setPost({...post, body: e.target.value})}
            />
            <MyButton style={{textAlign: 'center'}} onClick={addNewPost}>
                ADD ITEM
            </MyButton>
        </form>
    );
};

export default AddItem;