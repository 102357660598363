import React, {useEffect, useRef} from 'react';
import q from './Main.module.css'
import {useDispatch, useSelector} from "react-redux";
import MyButton from "../UI/myButton/MyButton";

const Main = () => {

    const dispatch = useDispatch();
    const posts = useSelector(state => state.pr.posts);
    const lastElement = useRef();
    const observer = useRef();

    useEffect(() => {
        let callback = function (entries, observer) {
            if (entries[0].isIntersecting) {
                console.log('пересечение')
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastElement.current);
    }, []);

    const removePost = (p) => {
        dispatch({type: "DELL", payload: p})
    };

    return (
        <div className={q.main}>
            {!posts.length
                ?
                <h1>Empty...</h1>
                :
                posts.map((p, idx) => {
                    return (
                        <div className={q.box} key={p.id}>
                            <div className={q.title}>{idx + 1}</div>
                            <div className={q.title}>{p.title}</div>
                            <div className={q.body}>{p.body}</div>
                            <img src="" alt=""/>
                            <MyButton onClick={() => removePost(p)} style={{marginLeft: 'auto'}}>
                                dell
                            </MyButton>
                        </div>
                    )
                })
            }


            <div ref={lastElement} className={q.observer}/>
        </div>
    );
};

export default Main;