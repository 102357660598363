import React from 'react';
import q from './MyButton.module.css'

const MyButton = ({children, ...props}) => {

    return (
        <div {...props} className={q.button}>
            {children}
        </div>
    );
};

export default MyButton;