import {combineReducers, createStore} from "redux";

const defaultState = {
    posts: [
        {id: 1, title: 'JavaScript1', body: 'Description1'},
        {id: 2, title: 'JavaScript2', body: 'Description2'},
        {id: 3, title: 'JavaScript3', body: 'Description3'},
        {id: 4, title: 'JavaScript4', body: 'Description4'},
    ],
    links: [
        {id: 1, name: 'Главная', action: '', path: ''},
        {id: 2, name: 'ADD ITEM', action: 'popup', path: ''},
        {id: 3, name: 'Контакты', action: '', path: ''},
        {id: 4, name: 'Услуги', action: '', path: ''},
        {id: 5, name: 'Шмунслуги', action: '', path: ''},
        {id: 6, name: 'Меню', action: '', path: ''},
        {id: 7, name: 'Шменю', action: '', path: ''},
        {id: 'exit', name: 'Выход', action: '', path: ''},
    ],
};


const postReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "ADD":
            console.log(action.payload)
            return {...state, posts: [...state.posts, action.payload]};
        case "DELL" :
            return {...state, posts: state.posts.filter(p => p.id !== action.payload.id)};
        default:
            return state
    }
};


const linkReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "ADMIN": return state;
        case "MASTER": return state;
        case "USER": return state;
        default: return state
    }
};
const rootReducer = combineReducers({
        pr: postReducer,
        lnk: linkReducer,
    }
);

export const store = createStore(rootReducer);