import React from 'react';
import q from './MyPopup.module.css'

const MyPopup = ({children, sp}) => {
    return (
        <div className={q.popup} onClick={() => sp(false)} title={'click here to close popup'}>
            <div className={q.popupItems} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default MyPopup;