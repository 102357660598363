import React, {useEffect, useState} from 'react';
import q from './Header.module.css'
import classNames from "classnames";
import useWindowDimensions from "../UI/wh";
import MyButton from "../UI/myButton/MyButton";
import {useSelector} from "react-redux";
import MyPopup from "../UI/myPopup/MyPopup";
import AddItem from "../addItem/AddItem";

const Header = () => {

    const links = useSelector(state => state.lnk.links);


    const width = useWindowDimensions();
    const [isOpen, setIsOpen] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [burg, setBurg] = useState();
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [au, setAu] = useState(false)


    useEffect(() => {
        if (width.width < 768) {
            setBurg(true);
        } else {
            setBurg(false);
        }
    }, [width]);

    const hum = (arg) => {
        if (burg) {
            setIsOpen(!isOpen);
        }
        if (arg === 'exit') {
            if (width.width < 768) {
                setTimeout(
                    auth
                    , 600)
            } else {
                auth()
            }
        }
    };
    const auth = () => {
        switch (isAuth) {
            case true:
                setIsAuth(false);
                break;
            case false:
                setIsAuth(true);
                break;
            default:
                setIsAuth(false);
        }
        setAu(false)
    };


    const handler1Click = (p) => {
        hum(p.id);
        if (p.action === 'popup') {
            setPopupIsOpen(true);
        }
    };


    return (

        <header>

            {popupIsOpen && <MyPopup sp={setPopupIsOpen} >
                <AddItem sp={setPopupIsOpen}/>
            </MyPopup> }

            {au && <MyPopup sp={setPopupIsOpen} >
                <MyButton onClick={auth}>
                    Authorized
                </MyButton>
            </MyPopup> }

            <div className={q.headerItems}>
                <div className={q.logo}>AllBiz</div>

                {!isAuth
                    ?
                    <MyButton style={{color: 'white', borderColor: 'white'}}
                              onClick={() => setAu(true)}>Авторизация</MyButton>
                    :
                    <div>
                        <div className={classNames(
                            q.navBar,
                            {[q.opn]: isOpen},
                        )}>
                            <ul>
                                {links.map(p => (
                                    <li
                                        onClick={() => handler1Click(p)}
                                        key={p.id}
                                    >
                                        {p.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {burg
                            ?
                            <div className={q.burg} onClick={hum}>
                                <div className={classNames(
                                    q.burgLine,
                                    {[q.active]: isOpen},
                                )}
                                />
                            </div>
                            : ''
                        }
                    </div>
                }


            </div>
        </header>
    );
};

export default Header;